<template>
  <div>
    <b-card>
      <div v-show="show.view">
        <create-return @emitWatcher="emitWatcher"></create-return>
      </div>
      <div v-if="!show.view" class="loading-createR">
        <b-spinner variant="primary"/>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import CreateReturn from '../../details/tabs/InfoReturn.vue'
export default {
  name: 'create-returns',
  components: { CreateReturn },
  data() {
    return {
      show: {
        view: false
      }
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {}
  },
  mounted() {},
  methods: {
    emitWatcher (values) {
      Object.keys(values).map(key => this.show = { ...this.show, [key]: values[key]})
    }
  }
}
</script>

<style>
.loading-createR{
  margin: auto;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>